import { AppRoute } from './app.routes';
import { AdminGuard } from './shared/guards/AdminGuard';

export const AdminRoutes: AppRoute[] = [
  {
    path: '',
    redirectTo: 'location',
    pathMatch: 'full',
    data: {
      menu: [],
    },
  },
  {
    path: 'location',
    loadComponent: () =>
      import(
        './components/admin/admin-locations/admin-locations.component'
      ).then((m) => m.AdminLocationsComponent),
    canActivate: [AdminGuard],
    data: {
      title: 'Locations',
      menu: ['admin'],
      icon: 'place', // updated icon
      path: 'admin/location', // updated path
    },
    title: 'Locations',
  },
  // The team route is removed from the application but it can be used as a skeleton for a new feature or reenabled by uncommenting the code below.
  // {
  //   path: 'team',
  //   loadComponent: () =>
  //     import('./components/admin/admin-team/admin-team.component').then(
  //       (m) => m.AdminTeamComponent
  //     ),
  //   canActivate: [AdminGuard],
  //   data: {
  //     title: 'Our Team',
  //     header: 'admin',
  //     menu: ['admin'],
  //     icon: 'group',
  //     path: 'admin/team',
  //   },
  //   title: 'Our Team',
  // },

  {
    path: 'resources',
    loadComponent: () =>
      import(
        './components/admin/admin-resources/admin-resources.component'
      ).then((m) => m.AdminResourcesComponent),
    canActivate: [AdminGuard],
    data: {
      title: 'Resources',
      menu: ['admin'],
      icon: 'library_books', // updated icon
      path: 'admin/resources', // updated path
    },
    title: 'Resources',
  },
  {
    path: 'events',
    loadComponent: () =>
      import('./components/admin/admin-events/admin-events.component').then(
        (m) => m.AdminEventsComponent
      ),
    canActivate: [AdminGuard],
    data: {
      title: 'Events',
      menu: ['admin'],
      icon: 'event', // updated icon
      path: 'admin/events', // updated path
    },
    title: 'Events',
  },
  {
    path: 'content',
    loadComponent: () =>
      import('./components/admin/admin-content/admin-content.component').then(
        (m) => m.AdminContentComponent
      ),
    canActivate: [AdminGuard],
    data: {
      title: 'Content',
      menu: ['admin'],
      icon: 'description', // updated icon
      path: 'admin/content', // updated path
    },
  },
  {
    path: 'help',
    loadComponent: () =>
      import('./components/admin/admin-help/admin-help.component').then(
        (m) => m.AdminHelpComponent
      ),
    canActivate: [AdminGuard],
    data: {
      title: 'Help',
      menu: ['admin'],
      icon: 'help', // updated icon
      path: 'admin/help', // updated path
    },
    title: 'Help',
  },
  {
    path: 'manageusers',
    loadComponent: () =>
      import(
        './components/admin/admin-manage-users/admin-manage-users.component'
      ).then((m) => m.AdminManageUsersComponent),
    canActivate: [AdminGuard],
    data: {
      title: 'Admins',
      menu: ['admin'],
      icon: 'supervisor_account', // updated icon
      path: 'admin/manageusers', // updated path
    },
    title: 'Admins',
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('./components/admin/admin-settings/admin-settings.component').then(
        (m) => m.AdminSettingsComponent
      ),
    canActivate: [AdminGuard],
    data: {
      title: 'Settings',
      menu: ['admin'],
      icon: 'settings', // updated icon
      path: 'admin/settings', // updated path
    },
    title: 'Settings',
  },
];
