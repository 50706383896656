import { Injectable } from '@angular/core';
import { Guard } from './guard';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard extends Guard {
  constructor(readonly authenticationService: AuthenticationService) {
    super(authenticationService);
  }

  async canActivate(): Promise<boolean> {
    const allowedRole = 'admin';
    const userRoles = await this.authenticationService.getRoles();
    if (!userRoles) {
      console.log('AdminGuard:canActivate', userRoles);
      return false;
    }
    return userRoles.includes(allowedRole);
  }
}
